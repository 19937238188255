import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import ExploreCourses from './pages/ExploreCourses/ExploreCourses';
import Subscribed from './pages/Subscribed/Subscribed';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Routes, Route, useLocation } from "react-router-dom";
import MainServiceSec from './pages/Services/MainServiceSec';
import MainMore from './pages/More/MainMore';
import MoreUpcomingEven from './pages/More/MoreUpcoming/MoreUpcomingEven';
import MainCarrerPage from './pages/More/NewCareer/MainCarrerPage';
import MainMoreAbout from './pages/More/MoreAboutSection/MainMoreAbout';
import Blog from './pages/Blog/Blog';
import FaviconImage from './assets/kitna.png'
import LoadingGif from './assets/Loading.gif';


function App() {
  const [showSubscribed, setShowSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Dynamically set the favicon
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = FaviconImage; // Set the imported image as favicon
    }

    // Simulate a loading effect when the page initially loads
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Loading duration: 1 second

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Show loading animation on route change
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Loading duration: 1 second

    return () => clearTimeout(timer);
  }, [location.pathname]);


  const handleSubscribe = (enteredEmail) => {
    setEmail(enteredEmail);
    setShowSubscribed(true);
  };


  const handleCloseModal = () => {
    setShowSubscribed(false);
    setEmail('');
    setResetEmail(true);
  };

  return (
    <>
      {loading ? (
        // Show loading GIF while loading state is true
        <div className="loading-container">
          <img src={LoadingGif} alt="Loading..." className="loading-gif" />
        </div>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/explorecourses" element={<ExploreCourses />} />
            <Route path="/services" element={<MainServiceSec />} />
            <Route path="/more" element={<MainMore />} />
            <Route path="/upcomingevent" element={<MoreUpcomingEven />} />
            <Route path="/career" element={<MainCarrerPage />} />
            <Route path="/morecareer" element={<MainMoreAbout />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
          <Footer onSubscribe={handleSubscribe} resetEmail={resetEmail} setResetEmail={setResetEmail} />

          {showSubscribed && (
            <Subscribed email={email} onClose={handleCloseModal} />
          )}
          <ToastContainer theme="dark" toastContainerClassName="custom-toast-container" />
        </>
      )}
    </>
  );
}

export default App;
